import {useParams} from "react-router-dom";

import {useGetProductByAliasQuery} from "../../../common/features/product/productsSlice";
import React, {useEffect} from "react";
import BallLoader from "../../../common/components/BallLoader";
import TestBuyPageDesktopInside from "./TestBuyPageDesktopInside";
import {useDispatch, useSelector} from "react-redux";
import {getMobile, setGlobalLoader} from "../../../common/features/user/settingsSlice";
import TestPreparationRequest from "../../../common/features/product/TestPreparationRequest";
import LanguageLevelTestButton from "../../../common/features/languageLevelTest/LanguageLevelTestButton";
import {Helmet} from "react-helmet-async";
import useToolService from "../../../common/hooks/useToolService";
import {useNavigate} from "react-router";
import {setBlackBackground, setVisibleConsultationModal} from "../../../common/features/application/applicationSlice";
import {useTranslation} from "react-i18next";

const TestBuyPageDesktop = () => {
    const { t } = useTranslation();
    const {alias} = useParams();
    const dispatch = useDispatch();
    const {getTranslatedValue, getLanguageLinksForPages, prepareMeta} = useToolService();
    const isMobile = useSelector(getMobile)
    let buyCardInside = "buyCardInside";
    let imageBuyForm = "imageBuyForm";
    const navigate = useNavigate();
    const {preparingUrlForNavigation} = useToolService();
    const contentClickHandler = (e) => {
        navigate(preparingUrlForNavigation(e));
    };
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetProductByAliasQuery(alias);


    let globalContent = "";

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    },[isLoading, isSuccess])

    const openConsultationModal = () => {
        dispatch(setVisibleConsultationModal(true))
        dispatch(setBlackBackground(true))
    }
    let content;

    if(isSuccess){
        if(data.prices.length===0){
            globalContent = <TestPreparationRequest data={data} alias={alias} />
        }else if(data.prices.length===1 && data.prices[0].value === 0){
            globalContent = <LanguageLevelTestButton productObject={data} alias={alias} />
        }else{
            globalContent = <TestBuyPageDesktopInside data={data} alias={alias} />;
        }
        content = <>
                    <div className={isMobile ? "wrapper2" : "wrapper"}>
                        <div className="buyCard">
                            <div className={isMobile ? "buyCardInsideWide" : buyCardInside} itemScope itemType="http://schema.org/Product" >
                                <Helmet
                                    link={getLanguageLinksForPages("/products/"+alias)}
                                    meta={prepareMeta(getTranslatedValue(data.titles), getTranslatedValue(data.descriptions), "product", "/products/"+alias, "")}
                                    title={getTranslatedValue(data.titles)}
                                />
                                <img itemProp="image" className={isMobile ? "imageBuyFormMobile" : imageBuyForm} alt={getTranslatedValue(data.titles)} src={data.picture} />

                                <h1 itemProp="name">{data.h1.length !== 0 ? getTranslatedValue(data.h1) : getTranslatedValue(data.titles)}</h1>

                                <div onClick={contentClickHandler} itemProp="description" dangerouslySetInnerHTML={{__html: getTranslatedValue(data.body)}}/>
                                {globalContent}
                            </div>
                        </div>

                    </div>
            </>

    }
    return content;


}

export default TestBuyPageDesktop;