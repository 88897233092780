import {apiSlice} from "../../app/api/apiSlice";
import {GET_PAGE_BY_ALIAS} from "../../app/repository";

export const pageSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPageByAlias: builder.query({
            query: (alias) => {
                return GET_PAGE_BY_ALIAS+alias},
            providesTags: (result, error, arg) => [{ type: 'Pages', id: arg }]
        }),
    })
})

export const {
    useGetPageByAliasQuery
} = pageSlice