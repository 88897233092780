import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "../features/user/settingsSlice";
import {
    setForgotPasswordModal,
    setTestStatus,
    setVisibleLoginModal,
    setVisibleNewPasswordModal,
    setVisibleRegistrationModal,
    setVisibleWarningCancelModal
} from "../features/application/applicationSlice";
import {selectCurrentToken} from "../features/auth/authSlice";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {GET_FILE} from "../app/repository";
import {getAnswers, setAnswers} from "../features/commonTestData/testAnswersSlice";
import {getFirstQuestionId, setCurrentQuestionId, setFilledNumbers} from "../features/commonTestData/testProgressLine";

const useToolService = () => {
    const language = useSelector(getLanguage);
    const token = useSelector(selectCurrentToken)
    const dispatch =   useDispatch();
    const firstId = useSelector(getFirstQuestionId)
    const { t, i18n } = useTranslation();
   // const navigate = useNavigate();
    const answers = useSelector(getAnswers)
    const allowedLanguages = ['/ru/', '/en/', '/es/', '/cn/', '/de/', '/fr/', '/kz/']
    const allowedShortLanguages = ['ru', 'en', 'es', 'cn', 'de', 'fr', 'kz']
    const getTranslatedValue = (objects, l) => {

        let newLanguage = language

        //console.log(newLanguage, language)
        if(l){
            newLanguage = l;
        }
        if(allowedShortLanguages.includes(newLanguage.toLowerCase())){

            return objects.filter(title=>title.language.title === newLanguage)[0].value;
        }
        else{

            return objects.filter(title=>title.language.title === "US")[0].value;
        }


    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const isTooBig = (s) => {
        return s.length >50;
    }


    const checkAnswerInsideAnswers = (id) => {
        const tempArray = answers.filter((entity)=>entity.key === id)

        if(tempArray.length > 0){
            return tempArray[0].answers.flatMap(answer => answer.value)
        }
        else{
            return []
        }
    }

    const preparingUrlForNavigation = (e) => {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        e.preventDefault();
        return targetLink.href.replace(/^.*\/\/[^\/]+/, '')

    };

    const getLanguageLinksForPages = (path) => {
        const l = i18n.language === "US" ? "en" : i18n.language.toLowerCase()
        const languages = allowedShortLanguages.filter((lan)=>lan !== l);

        return languages.map(language=>{

            return {"rel": "alternate", hreflang: language, "href": "https://many-tests/"+language+path}
        })

     }

     const prepareMeta = (title, description, type, path, picture) => {


        const l = language === "US" ? "en" : language.toLowerCase();
        return [
            {
                property: "og:site_name",
                content: "Many Tests"
            },
            {
                name: "description",
                content: description
            },
            {
                property: "og:title",
                content: title
            },
            {
                property: "og:type",
                content: type
            },
            {
                property: "og:url",
                content: "https://many-tests.com/"+l+path
            },
            {
                property: "og:image",
                content: "https://many-tests.com/img/"+picture
            },
            {
                property: "twitter:title",
                content: title
            },
            {
                property: "twitter:description",
                content: description
            },
            {
                property: "twitter:image",
                content: "https://many-tests.com/img/"+picture
            },
            {
                property: "twitter:card",
                content: "summary"
            },
        ]
     }

    const getLinkFromEmail = (email) => {
        const domain = email.replace(/.*@/, "");
        switch (domain){
            case "gmail.com":
                return "https://mail.google.com/mail/u/0/#inbox"
            case "yandex.ru":
                return "https://mail.yandex.ru/#inbox"
            case "mail.ru":
                return "https://e.mail.ru/inbox/"
            case "live.com":
                return "https://outlook.live.com/mail/0/"
            case "hotmail.com":
                return "https://outlook.live.com/mail/0/"
            default:
                return ""
        }
    }



/*
    const save = async (api, orderId, url) => {
        try {
            dispatch(setTestStatus("loading"))
            const result = await api({answers, orderId}).unwrap();
            dispatch(setTestStatus("await"))
            navigate(url+result)

        } catch (err) {
            console.log(err)
        }
    }
*/
    const downloadEssay = (id, attribute) => {

        axios.get(GET_FILE+id+"&type=1",{ headers: {"Authorization" : `Bearer ${token}`}, responseType: 'blob'}).then(result=>{

            const href = URL.createObjectURL(result.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', attribute); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(error=>{
            console.log(error)
        })
    }


    const checkEmptyLength = (target, errorAction, validAction) => {
        if(target.length === 0){
            dispatch(errorAction(t("InvalidEmptyField")))
            dispatch(validAction(false))
        }
    }

    const registrationHandler = () => {
        dispatch(setForgotPasswordModal(false));
        dispatch(setVisibleNewPasswordModal(false));
        dispatch(setVisibleLoginModal(false));
        dispatch(setVisibleRegistrationModal(true));
    }
    const forgotPasswordHandler = () => {
        dispatch(setVisibleNewPasswordModal(false));
        dispatch(setForgotPasswordModal(true));
        dispatch(setVisibleLoginModal(false));
        dispatch(setVisibleRegistrationModal(false));
    }

    const loginHandler = () =>{
        dispatch(setVisibleNewPasswordModal(false));
        dispatch(setVisibleLoginModal(true));
        dispatch(setVisibleRegistrationModal(false));
        dispatch(setForgotPasswordModal(false));
    }

    const getIcon = (fileType) => {
        switch (fileType){
            case "docx":
                return "/img/Circle-icons-docx.png"

            case "doc":
                return "/img/Circle-icons-doc.png"
            case "odt":
                return "/img/Circle-icons-odt.png"
            default:
                return "/img/Circle-icons-document.png"
        }

    }


    const resetTest = () => {
        dispatch(setVisibleWarningCancelModal(false));
        dispatch(setTestStatus("await"))
        dispatch(setCurrentQuestionId(0))
        dispatch(setFilledNumbers([]))
        dispatch(setAnswers([]))
        dispatch(setCurrentQuestionId(firstId))
    }

    return{checkAnswerInsideAnswers, allowedShortLanguages, prepareMeta, getLanguageLinksForPages, preparingUrlForNavigation, allowedLanguages, resetTest, getIcon, downloadEssay, checkEmptyLength, getLinkFromEmail, isTooBig, getTranslatedValue, isValidEmail, registrationHandler, forgotPasswordHandler, loginHandler}
}



export default useToolService;