import {useGetAllProductsQuery} from "./productsSlice";
import {useDispatch, useSelector} from "react-redux";
import {getMobile, setGlobalLoader} from "../user/settingsSlice";
import useToolService from "../../hooks/useToolService";
import {Helmet} from "react-helmet-async";
import React, {useEffect} from "react";

import MainPageTestCatalogMobile from "../../../mobile/features/tests/MainPageTestCatalogMobile";
import MainPageTestCatalog from "../../../desktop/features/tests/MainPageTestCatalog";
import {useTranslation} from "react-i18next";
import {useGetPageByAliasQuery} from "../page/pageSlice";

const Products = () => {
    const isMobile = useSelector(getMobile)
    const {t} = useTranslation()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllProductsQuery();
    const {
        data: dataPage,
        isLoading: isLoadingPage,
        isSuccess: isSuccessPage,
        isError: isErrorPage,
        error: errorPage
    } = useGetPageByAliasQuery("productsPage");

    const dispatch = useDispatch()
    let content;
    const {getTranslatedValue} = useToolService();

    useEffect(()=>{
        if(isLoading){
            dispatch(setGlobalLoader(true))
        }
        if(isSuccess){
            dispatch(setGlobalLoader(false))
        }
    }, [isLoading, isSuccess])


    if(isSuccess){

        content = isMobile
            ? <MainPageTestCatalogMobile data={data} removeTitle={true} />
            : <MainPageTestCatalog data={data} removeTitle={true} />
    }
    const {getLanguageLinksForPages, prepareMeta} = useToolService();

    let h1 = t("Products");
    let title = t("Products")+" Many Tests";
    let meta = prepareMeta(t("Products")+" Many Tests", t("Products")+" Many Tests", "website", "/", "");
    let body = "";
    if(isSuccessPage){
        h1 = getTranslatedValue(dataPage.h1.length > 0 ? dataPage.h1 : dataPage.titles)
        title = getTranslatedValue(dataPage.titles)
        body = getTranslatedValue(dataPage.body);
        meta = prepareMeta(title, getTranslatedValue(dataPage.descriptions), "website", "/", dataPage.picture);
    }

    return <>
        <Helmet   link={getLanguageLinksForPages("/products/")}
                  meta={meta}
                  title={title}
        />
        <div className={isMobile ? "wrapper2": "wrapper"}>
        <div className="buyCard">
            <div className="buyCardInsideWide">
                <h1>{h1}</h1>
                <div dangerouslySetInnerHTML={{__html: body}}/>
                {
                    content
                }

            </div>
        </div>
        </div>
    </>
}

export default Products;